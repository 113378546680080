module.exports = {
	validContactForm: function(data) {
		var errors = {};
		if(!data['234837'] || data['234837'].trim() === '') {
			errors['234837'] = 'Your name is required.';
		}
		if(!data['234838'] || data['234838'].trim() === '') {
			errors['234838'] = 'Your email is required.';
		} else {
			if(!/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(data['234838'])) {
				errors['234838'] = 'Your email is not valid.';
			}
		}
		return {
			valid: $.isEmptyObject(errors),
			errors: errors
		}
	},
	isInViewPort: function(e) {
		var eleTop = e.offset().top;
		var eleBottom = eleTop + e.outerHeight();
		var viewTop = $(window).scrollTop();
		var viewBottom = viewTop + $(window).height();
		return eleBottom > viewTop && eleTop < viewBottom;
	}
}
