window.$ = window.jQuery = require('jquery');
require('bootstrap-sass');
var utils = require('./utils.js');
var validContactForm = utils.validContactForm;
var isInViewPort = utils.isInViewPort;

$(document).ready(function() {
	var photo = $('.photo > .wrap');
	photo.on('mouseover', function() {
		$(this).find('.caption').animate({
			bottom: 0
		}, 300);
	});
	photo.on('mouseleave', function() {
		$(this).find('.caption').animate({
			bottom: '-100%'
		}, 300);
	});

	var video = $('#video-banner');
	$(window).on('scroll resize', function() {
		var isVideoVisible = isInViewPort(video);
		if(isVideoVisible) {
			video[0].play();
		} else {
			video[0].pause();
		}
	});

	var soundBtn = $('.toggle-btn.sound');
	$('#video-banner, .sound').on('click', function(e) {
		e.preventDefault();
		soundBtn.show();
		if(soundBtn.hasClass('off')) {
			soundBtn.removeClass('off');
			video[0].muted = false;
		} else {
			soundBtn.addClass('off');
			video[0].muted = true;
		}
		setTimeout(function() {
			soundBtn.fadeOut();
		}, 4000);
	});

	$('#main-menu a').on('click', function(e) {
		e.preventDefault();
		var targetId = $(this).attr('href').replace('#', '');
		var targetEleTop = $('#' + targetId).offset().top;
		$('#main-menu-collapse').collapse('hide');
		$('html, body').animate({
			scrollTop: targetEleTop - 80
		}, 500);
	});

	var form = $('#contact-form-pipe-drive');
	var btn = $('#submit-contact');
	var thanks = $('.thanks');
	$('.form-group').removeClass('has-error');
	$('.help-block').addClass('hidden').html('');
	btn.on('click', function(e) {
		e.preventDefault();
		var formData = form.serialize();
		var data = formData.split('&');
		var resultData = {};
		$('.form-group').removeClass('has-error');
		$('.help-block').addClass('hidden').html('');
		data.forEach(function(item) {
			var itemData = item.split('=');
			resultData[itemData[0]] = decodeURIComponent(itemData[1]);
		});
		var validation = validContactForm(resultData); 
		if(validation.valid) {
			btn.attr('disabled', true);
			btn.html('Submitting Data...');
			$.ajax({
				url: form.attr('action'),
				method: 'POST',
				data: resultData,
				cache: false
			}).done(function(res) {
				console.log(res)
				btn.hide();
				thanks.removeClass('hidden');
				form.find('.form-control').val('');
			}).fail(function(err) {
				console.log(err);
			});
		} else {
			var errors = validation.errors;
			$.each(errors, function(k, v) {
				var parent = $('#' + k).parents('.form-group');
				parent.addClass('has-error');
				parent.find('.help-block').removeClass('hidden').html(v);
			});
		}
	});	

	if($('#petlytics-cta').length) {
		var windowHeight = $(window).height();
		var menuHeight = $('header').height();
		var titleHeight = $('#petlytics-title').height();
		var ctaHeight = $('#petlytics-cta').height() + 15;
		var videoHeight = windowHeight - menuHeight - titleHeight - ctaHeight;
		$('#video-banner').css({
			maxHeight: videoHeight + 'px',
			width: 'auto'
		});
		$('.video-wrap.petlytics').css({
			maxHeight: videoHeight + 'px',
			maxWidth: (videoHeight / 9 * 16) + 'px',
			width: 'auto'
		});
	}
});
